import styled from "styled-components";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import Header from "./components/Header";
import SignIn from "./components/SignIn";
import Companies from "./components/Companies";
import Company from "./components/Company";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const Routes = () => {
  let routes = useRoutes([
    { path: "/", element: <SignIn /> },
    {
      path: "/companies",
      element: (
        <>
          <Header />
          <Companies />{" "}
        </>
      ),
    },
    {
      path: "/company/:id",
      element: (
        <>
          <Header />
          <Company />
        </>
      ),
    },
  ]);
  return routes;
};

function App() {
  return (
    <MainContainer>
      <Router>
        <Routes></Routes>
      </Router>
    </MainContainer>
  );
}

export default App;
