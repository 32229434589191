import { useState } from "react";
import styled from "styled-components";

import Row from "./Row";

const MainContainer = styled.div`
  overflow-y: scroll;
  padding-bottom: 50px;
`;

export default function List(props) {
  const [selected, setSelectedState] = useState(null);

  const rows = [];

  for (let i = 0; i < props.companies.length; i++) {
    if (selected === i) {
      rows.push(
        <Row
          key={props.companies[i].id}
          companyId={props.companies[i].id}
          data={props.companies[i]}
          selected
          setSelected={() => setSelectedState(null)}
        ></Row>
      );
    } else {
      rows.push(
        <Row
          key={props.companies[i].id}
          companyId={props.companies[i].id}
          data={props.companies[i]}
          setSelected={() => setSelectedState(i)}
        ></Row>
      );
    }
  }

  return <MainContainer>{rows}</MainContainer>;
}
