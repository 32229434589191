import { Auth } from "aws-amplify";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import bgImg from "../assets/background.svg";

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${bgImg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 1px;
  padding: 0 25px 50px 25px;
  border-radius: 10px;
`;

const Title = styled.p`
  text-align: center;
  margin: 40px 0;
  font-weight: 500;
`;

const Input = styled.input`
  width: 350px;
  height: 55px;
  border: 1px solid gray;
  padding: 10px 20px;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 14px;
  margin-bottom: 20px;
`;

const Submit = styled.button`
  padding: 15px 25px;
  font-weight: 500;
  background: rgb(85, 144, 255);
  box-sizing: border-box;
  border-radius: 100px;
  font-family: Roboto;
  font-size: 14px;
  border: none;
  color: white;
  align-self: flex-start;
  margin-top: 20px;
  cursor: pointer;
`;

export default function SignIn() {
  const [username, setUsernameState] = useState("");
  const [password, setPasswordState] = useState("");

  const navigate = useNavigate();

  return (
    <>
      <MainContainer>
        <Box>
          <Title>Inicia sesión</Title>
          <Input
            type="text"
            placeholder="Correo"
            value={username}
            onChange={(e) => setUsernameState(e.target.value)}
          ></Input>
          <Input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => {
              setPasswordState(e.target.value);
            }}
          ></Input>
          <Submit
            onClick={async () => {
              try {
                const session = await Auth.signIn(username, password);
                sessionStorage.setItem(
                  "jwt",
                  session.signInUserSession.idToken.jwtToken
                );

                if (
                  session.signInUserSession.accessToken.payload[
                    "cognito:groups"
                  ].includes("Backoffice")
                ) {
                  navigate(`/companies`);
                } else {
                  alert("Ha habido un error");
                }
              } catch (err) {
                alert("Ha habido un error");
              }
            }}
          >
            Iniciar sesión
          </Submit>
        </Box>
      </MainContainer>
    </>
  );
}
