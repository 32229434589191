import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  padding: 50px 150px;
  width: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
`;

const SecondaryContainer = styled.div`
  padding: 0px 150px 50px 150px;
  margin-top: 20px;
  width: 100%;
  flex-grow: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
`;

const CompanyName = styled.p`
  font-weight: 700;
  font-size: 45px;
  margin-bottom: 60px;
  margin-top: 20px;
  color: #323946;
`;

const Table = styled.div``;

const Row = styled.div`
  display: flex;
  margin: 25px 0;
`;

const Attr = styled.p`
  color: gray;
  font-size: 17px;
  width: 200px;
`;

const Value = styled.p`
  font-size: 17px;
  color: #323946;
`;

const Data = styled.div`
  margin: 70px 0 100px 0;
  #title {
    font-weight: 700;
    font-size: 25px;
    color: #323946;
    margin: 20px 0px;
  }
  .row {
    margin: 30px 0px;
    font-size: 17px;
    color: #323946;
    span {
      background: #efefef;
      color: rgb(213, 30, 72);
      border-radius: 5px;
      padding: 5px 10px;
    }
  }
  .activity {
    width: 300px;
    font-weight: 500;
    font-size: 17px;
  }
  .date {
    width: 300px;
    font-size: 17px;
  }
  .tag {
    border-radius: 5px;
    padding: 5px 15px;
    color: white;
    font-weight: 500;
  }
  .color_1 {
    background: #f1c0b9;
  }
  .color_2 {
    background: #01949a;
  }
  .color_3 {
    background: #004369;
  }
  .color_4 {
    background: #08313a;
  }
  .color_5 {
    background: #ded93e;
  }
  .color_6 {
    background: #5cd85a;
  }
`;

const Warning = styled.span`
  color: rgb(213, 30, 72);
  font-weight: 700;
  font-size: 20px;
  border: 3px solid rgb(213, 30, 72);
  display: inline-block;
  margin: 10px 10px;
  border-radius: 10px;
  padding: 10px 15px;
`;

export default function Company() {
  const [users, setUsersState] = useState([]);
  const [locations, setLocationsState] = useState([]);
  const [movements, setMovementsState] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_COMPANIES_ENDPOINT}/${state.id}/contacts`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    })
      .then((response) => {
        setUsersState(response.data.body.users);
        setLocationsState(response.data.body.locations);
      })
      .catch((err) => console.log(err));
  }, [state.id]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_COMPANIES_ENDPOINT}/${state.id}/movements`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    })
      .then((response) => {
        console.log(response);
        setMovementsState(response.data.body);
      })
      .catch((err) => console.log(err));
  }, [state.id]);

  const usersGroup = [];
  for (let i = 0; i < users.length; i++) {
    usersGroup.push(
      <>
        <p key={users[i].id} className="row">
          <span>{users[i].fullname}</span> con correo{" "}
          <span>{users[i].email}</span> es el/la{" "}
          <span>{users[i].job_title}</span>
        </p>
      </>
    );
  }

  const locationsGroup = [];
  for (let i = 0; i < locations.length; i++) {
    locationsGroup.push(
      <>
        <p key={locations[i].id} className="row">
          Ubicación <span>{locations[i].name}</span> con dirección{" "}
          <span>{locations[i].address}</span>
        </p>
      </>
    );
  }

  const activities = [];
  const notUsed = {
    USERS: true,
    ARTICLES: true,
    WAREHOUSES: true,
    MOVEMENTS: true,
    POSS: true,
    ORDERS: true,
  };

  for (let i = 0; i < movements.length; i++) {
    let type = "";
    let color = "";
    switch (movements[i].type) {
      case "USERS":
        type = "usuario(s)";
        color = "color_1";
        notUsed.USERS = false;
        break;
      case "ARTICLES":
        type = "artículo(s)";
        color = "color_2";
        notUsed.ARTICLES = false;
        break;
      case "WAREHOUSES":
        type = "bodega(s)";
        color = "color_3";
        notUsed.WAREHOUSES = false;
        break;
      case "MOVEMENTS":
        type = "movimiento(s)";
        color = "color_4";
        notUsed.MOVEMENTS = false;
        break;
      case "POSS":
        type = "punto(s) de venta";
        color = "color_5";
        notUsed.POSS = false;
        break;
      case "ORDERS":
        type = "orden(es)";
        color = "color_6";
        notUsed.ORDERS = false;
        break;
      default:
        break;
    }

    activities.push(
      <>
        <Row key={i}>
          <p className="activity">
            Registró {movements[i].qty} {type}
          </p>
          <p className="date">{new Date(movements[i].date).toLocaleString()}</p>
          <p className={`tag ${color}`}>{movements[i].type}</p>
        </Row>
      </>
    );
  }

  let sectionsWarning = [];

  Object.keys(notUsed).forEach((key) => {
    if (notUsed[key]) {
      sectionsWarning.push(key);
    }
  });

  sectionsWarning = sectionsWarning.map((e) => {
    return <Warning>{e}</Warning>;
  });

  return (
    <Container>
      <MainContainer>
        <CompanyName>{state.name}</CompanyName>
        <Table>
          <Row>
            <Attr>🟢 Estatus</Attr>
            <Value>Desconocido</Value>
          </Row>
          <Row>
            <Attr>📅 Fecha de registro</Attr>
            <Value>{new Date(state.created_date).toLocaleString()}</Value>
          </Row>
          <Row>
            <Attr>🧳 Actividad Comercial</Attr>
            <Value>{state.comercial_activity}</Value>
          </Row>
          <Row>
            <Attr>☎️ Teléfono</Attr>
            <Value>{state.phone}</Value>
          </Row>
          <Row>
            <Attr>🔗 Página web</Attr>
            <Value>{state.website}</Value>
          </Row>
          <Row>
            <Attr>📍 Dirección</Attr>
            <Value>{state.address}</Value>
          </Row>
          <Row>
            <Attr>🆔 Id de compañía</Attr>
            <Value>{state.id}</Value>
          </Row>
        </Table>
      </MainContainer>
      <SecondaryContainer>
        <Data>
          <p id="title">Contactos dentro de la compañía</p>
          {usersGroup.length > 0 ? (
            usersGroup
          ) : (
            <p>😭 No hay registros en base de datos</p>
          )}
        </Data>
        <Data>
          <p id="title">Ubicaciones dentro de la compañía</p>
          {locationsGroup.length > 0 ? (
            locationsGroup
          ) : (
            <p>😭 No hay registros en base de datos</p>
          )}
        </Data>
        <Data>
          {sectionsWarning.length >= 1 && (
            <>
              <p id="title">Secciones sin utilizar</p>
              {sectionsWarning}
            </>
          )}
        </Data>
        <Data>
          <p id="title">Actividad</p>
          <Table>{activities}</Table>
        </Data>
      </SecondaryContainer>
    </Container>
  );
}
