import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

import List from "./List";

const MainContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export default function Companies() {
  const [companies, setCompaniesState] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url: process.env.REACT_APP_COMPANIES_ENDPOINT,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    })
      .then((response) => {
        setCompaniesState(response.data.body);
      })
      .catch((err) => setCompaniesState([]));
  }, []);

  return (
    <MainContainer>
      <Header />
      <List companies={companies} />
    </MainContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  background: #fbfdff;
  align-items: center;
  border: 1px solid #e9eef5;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 20px 20px 0px 20px;
`;

const Field = styled.p`
  width: ${(props) => props.width};
  color: #8894ac;
  font-weight: 500;
`;

function Header() {
  return (
    <HeaderContainer>
      <Field width="30%">Compañia</Field>
      <Field width="17%">Teléfono</Field>
      <Field width="30%">Página web</Field>
      <Field width="23%">Estatus</Field>
    </HeaderContainer>
  );
}
