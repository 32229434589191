import { Auth } from "aws-amplify";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo.png";

const MainContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #323946;
  flex-shrink: 0;
  figure {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }
`;

const Logout = styled.button`
  background: #efefef;
  color: #323946;
  font-weight: 500;
  font-family: Roboto;
  font-size: 14px;
  border-radius: 100px;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
`;

export default function Header() {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <figure onClick={() => navigate(`/companies`)}>
        <img src={logo} alt="Logo"></img>
      </figure>
      <Logout
        onClick={async () => {
          await Auth.signOut({ global: true });
          sessionStorage.clear();
          navigate(`/`);
        }}
      >
        Cerrar sesión
      </Logout>
    </MainContainer>
  );
}
