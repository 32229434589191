import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import arrow from "../../assets/arrow.svg";

const BiggerContainer = styled.div`
  background: white;
  box-shadow: 1px 4px 18px -2px rgba(0, 0, 0, 0.33);
  margin-top: 20px;
  border-radius: 5px;
  margin: 20px 20px 0px 20px;
`;

const MainContainer = styled.div`
  cursor: pointer;
  display: flex;
  background: ${(props) => (props.selected === true ? "#5590FF" : "white")};
  align-items: center;
  border: ${(props) => (props.selected ? null : "1px solid #e9eef5")};
  border-radius: ${(props) => (props.selected ? "5px 5px 0 0" : "5px")};
  height: 65px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: ${(props) => (props.selected ? null : "0 20px")};
  margin-top: ${(props) => (props.selected ? null : "20px")};
`;

const SecondaryContainer = styled.div`
  display: flex;
  padding: 20px;
  box-sizing: border-box;
`;

const LeftBox = styled.div`
  padding: 20px;
  margin-right: 20px;
  background: #fbfdff;
  border-radius: 5px;
  border: 1px solid #e9eef5;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #title {
    font-weight: 500;
    font-size: 17px;
    color: #0e2c5e;
    margin-bottom: 15px;
  }
  #value {
    color: #707070;
  }
`;

const RightBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  #address {
    width: 100%;
  }
  #title {
    color: #8894ac;
    margin: 15px 0px;
  }
  #value {
    color: #0e2c5e;
    font-weight: 500;
  }
  #visualize {
    cursor: pointer;
    margin-top: 25px;
    padding: 10px 15px;
    color: white;
    background: #5590ff;
    border-radius: 5px;
    font-weight: 500;
  }
`;

const Field = styled.p`
  width: ${(props) => props.width};
  font-weight: 500;

  // With and without status
  color: ${(props) => {
    if (props.active === true) {
      return "rgb(43, 222, 115)";
    } else if (props.active === false) {
      return "rgb(213, 30, 72)";
    } else if (props.selected) {
      return "white";
    } else {
      return "#8894ac";
    }
  }};

  // Status
  padding: ${(props) => {
    if (props.active === true || props.active === false) {
      return "5px 10px";
    }
  }};
  background: ${(props) => {
    if (props.active === true) {
      return "rgba(43, 222, 115, .22)";
    } else if (props.active === false && props.selected === true) {
      return "rgba(255, 255, 255, .7)";
    } else if (props.active === false) {
      return "rgba(213, 30, 72, .22)";
    }
  }};
  border: ${(props) => {
    if (props.active === true) {
      return "green";
    } else if (props.active === false) {
      return "red";
    }
  }};
  border-radius: 5px;
`;

const Expand = styled.figure`
  width: 33px;
  height: 33px;
  background: #e9eef5;
  border-radius: 5px;
  cursor: pointer;
  transform: ${(props) => (props.selected ? "rotate(180deg)" : null)};
`;

const Space = styled.div`
  flex-grow: 1;
`;

export default function Row(props) {
  const navigate = useNavigate();

  if (props.selected) {
    return (
      <BiggerContainer>
        <MainContainer
          selected={props.selected}
          onClick={() => props.setSelected()}
        >
          <Field selected={props.selected} width="30%">
            {props.data.name}
          </Field>
          <Field selected={props.selected} width="17%">
            {props.data.phone}
          </Field>
          <Field selected={props.selected} width="30%">
            {props.data.website}
          </Field>
          <Field selected={props.selected} active={false}>
            Desconocido
          </Field>
          <Space></Space>
          <Expand selected={props.selected}>
            <img src={arrow} alt="Arrow"></img>
          </Expand>
        </MainContainer>
        <SecondaryContainer>
          <LeftBox>
            <p id="title">Fecha de registro</p>
            <p id="value">
              {new Date(props.data.created_date).toLocaleString()}
            </p>
          </LeftBox>
          <RightBox>
            <div id="address">
              <p id="title">Dirección</p>
              <p id="value">{props.data.address}</p>
            </div>
            <p
              id="visualize"
              onClick={() =>
                navigate(`/company/${props.companyId}`, { state: props.data })
              }
            >
              Visualizar negocio
            </p>
          </RightBox>
        </SecondaryContainer>
      </BiggerContainer>
    );
  } else {
    return (
      <MainContainer onClick={() => props.setSelected()}>
        <Field width="30%"> {props.data.name}</Field>
        <Field width="17%"> {props.data.phone}</Field>
        <Field width="30%">{props.data.website}</Field>
        <Field active={false}>Desconocido</Field>
        <Space></Space>
        <Expand>
          <img src={arrow} alt="Arrow"></img>
        </Expand>
      </MainContainer>
    );
  }
}
